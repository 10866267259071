import { PaginationConfig } from '~reviews/common/common-types';
import { listStub, makeDefaultServerConfiguration } from '~reviews/storybook-utils/enricher-stub';
import { FetchReviewsResponseFull, ServerOrdering } from './reviews-api-types';
import { currentMember, currentUserVotes } from '~reviews/storybook-utils/mock-list-data';
import { Fieldset, Permission, Review } from '@wix/ambassador-reviews-v1-enriched-review/types';
import { fromServerListResponseFull } from './reviews-api-mappers';
import { buildDemoContentForEntity } from '~commons/demo-content';

// @TODO: lazyload && multilingual
export const fetchReviewsEditor = async ({
  resourceId,
  pagination,
  cursor,
  namespace,
}: {
  resourceId: string;
  pagination: PaginationConfig;
  cursor?: string;
  namespace: string;
}): Promise<FetchReviewsResponseFull> => {
  return fromServerListResponseFull(
    listStub({
      limit: pagination.pageSize,
      fieldsets: [
        Fieldset.RATING_SUMMARY,
        Fieldset.MEMBER_NAMES,
        Fieldset.CURRENT_MEMBER,
        Fieldset.CURRENT_USER_VOTES,
        Fieldset.CURRENT_USER_REVIEW,
        Fieldset.PERMISSIONS,
        Fieldset.CONFIGURATION,
      ],
      cursor,
      sorting: pagination.ordering as ServerOrdering,
      ...(pagination.ratingFilter && { ratingFilter: pagination.ratingFilter }),
      reviews: buildDemoContentForEntity(resourceId).reviewList,
      permissions: [
        Permission.CREATE_REVIEW,
        Permission.UPDATE_REVIEW,
        Permission.DELETE_REVIEW,
        Permission.VOTE,
        Permission.SET_REPLY,
        Permission.REMOVE_REPLY,
      ],
      votes: currentUserVotes,
      currentMember,
      namespace: 'stores',
      contextId: '7bb38a7a-70b7-9cf3-fc80-584205694465',
      configuration: makeDefaultServerConfiguration({}),
    }),
  );
};

export const mockReviews = [
  {
    namespace: 'stores',
    entityId: '7bb38a7a-70b7-9cf3-fc80-584205694465',
    id: 'b4d484fc-f6bc-45d8-aafc-39117c33301f',
    content: {
      title: 'Highly recommend',
      body: `The online shopping experience was very easy and smooth. I was a bit hesitant if this is right for me. Though there is a lot of relevant information on their site I still wanted to double-check some product specs, like the material and dimensions. Their agents were so professional, attentive and provided very accurate information. 
Overall, I am quite satisfied with their quality.`,
      media: [],
      rating: 4,
    },
    createdDate: new Date('2022-04-25T08:30:11.876Z'),
    updatedDate: new Date('2022-05-05T11:47:17.078Z'),
    author: {
      contactId: '6c2960be-60f1-489e-9dd3-614e2acf4496',
      authorType: 'VISITOR',
      authorName: 'Ava Howard',
    },
    foundHelpful: 1,
    foundUnhelpful: 0,
    helpfulness: 1,
    reply: {
      message: 'Thank you so much!',
      createdDate: new Date('2022-04-26T08:30:11.876Z'),
      updatedDate: new Date('2022-04-26T10:42:35.116Z'),
    },
    moderation: {
      moderationStatus: 'APPROVED',
    },
    verified: true,
  },
  {
    namespace: 'stores',
    entityId: '7bb38a7a-70b7-9cf3-fc80-584205694465',
    id: '255bb8ed-9a39-4f8a-bb19-16c513aa7cff',
    content: {
      title: 'Julia is awesome',
      body: `I don't leave a lot of reviews but I just had to for this store because I'm just so impressed! 
Julia is one of their sales agents and she was so helpful and knowledgeable through the entire shopping experience (which was awesome). I chatted with her after 8 pm and she walked me through all the possible options, was eager to understand my needs and suggested the best product. 
I look forward to a smooth delivery!`,
      media: [],
      rating: 5,
    },
    createdDate: new Date('2022-04-07T10:51:51.794Z'),
    updatedDate: new Date('2022-04-07T20:42:12.045Z'),
    author: {
      contactId: '285cd0f3-f5b4-4184-8419-142edfedd9dd',
      authorType: 'VISITOR',
      authorName: 'Jack Richards',
    },
    foundHelpful: 2,
    foundUnhelpful: 0,
    helpfulness: 2,
    moderation: {
      moderationStatus: 'APPROVED',
    },
    verified: true,
  },
  {
    namespace: 'stores',
    entityId: '7bb38a7a-70b7-9cf3-fc80-584205694465',
    id: '4602f78d-4b3b-4641-816b-0d06984d64e0',
    content: {
      title: 'Wildly inexpensive for such great quality',
      body: `I was skeptical at first cause the price seemed way too good to be true but I decided to give it a shot. I’m so glad I did!

You can't go wrong with this product. At first, I was tempted by its lovely looks and design. But I honestly wasn’t hoping for it to be so easy to use and long-lasting. Finally, I got ALL those things.`,
      media: [],
      rating: 4,
    },
    createdDate: new Date('2022-04-07T08:04:41.899Z'),
    updatedDate: new Date('2022-04-07T08:04:41.899Z'),
    author: {
      contactId: '85b1734c-d4f5-48f6-abb3-f50730395c0f',
      authorType: 'VISITOR',
      authorName: 'Emily May',
    },
    foundHelpful: 0,
    foundUnhelpful: 0,
    helpfulness: 0,
    moderation: {
      moderationStatus: 'APPROVED',
    },
    verified: true,
  },
  {
    namespace: 'stores',
    entityId: '7bb38a7a-70b7-9cf3-fc80-584205694465',
    id: '0dc02a3e-b12a-4ab6-b3ef-13552deccb5f',
    content: {
      body: 'Great quality, beautifully built and easy to use! I think a lot of the lower reviews are harsh. Experienced no problems mentioned by other reviewers. It is a bit expensive, but what you get is well worth the price. Wanted to try different colors but decided to go with white - was not disappointed at all!',
      media: [],
      rating: 5,
      title: 'Negative reviews are over critical',
    },
    createdDate: new Date('2022-04-22T08:30:11.876Z'),
    updatedDate: new Date('2022-04-22T08:30:11.876Z'),
    author: {
      contactId: '33933212-4e29-47d2-b849-099297a2974f',
      authorType: 'VISITOR',
      authorName: 'Dennis Hathaway',
      email: 'subaduba@asd.asd',
    },
    foundHelpful: 0,
    foundUnhelpful: 0,
    helpfulness: 0,
    moderation: {
      moderationStatus: 'APPROVED',
      manuallyChanged: false,
    },
  },
  {
    namespace: 'stores',
    entityId: '7bb38a7a-70b7-9cf3-fc80-584205694465',
    id: '431c5d96-b66e-44ff-b6c6-d8994b8e4c11',
    content: {
      media: [],
      rating: 5,
      title: 'Lovely product',
      body: 'Excellent seller (not my first purchase), fast delivery, product as described and glad to see they reduced their packaging amounts!',
    },
    createdDate: new Date('2022-04-22T08:30:11.876Z'),
    updatedDate: new Date('2022-04-22T08:30:11.876Z'),
    author: {
      contactId: 'a2837f76-ba48-425f-8aa0-1e56512c5633',
      authorType: 'VISITOR',
      authorName: 'Annie Lee',
      email: 'klsldf@sf.as',
    },
    foundHelpful: 0,
    foundUnhelpful: 0,
    helpfulness: 0,
    moderation: {
      moderationStatus: 'APPROVED',
      manuallyChanged: false,
    },
  },
  {
    namespace: 'stores',
    entityId: '7bb38a7a-70b7-9cf3-fc80-584205694465',
    id: '1783d11a-7b32-464c-8a50-086af2bd4e9a',
    content: {
      media: [],
      rating: 4,
      title: 'Great present',
      body: 'Very good quality for its price. Got this as a gift for my nan, she loved it! Will definitely keep this site in mind when gift shopping the next time.',
    },
    createdDate: new Date('2022-04-22T08:30:11.876Z'),
    updatedDate: new Date('2022-04-22T08:30:11.876Z'),
    author: {
      contactId: 'b5966da5-5226-4241-910a-5383cceb3e4e',
      authorType: 'VISITOR',
      authorName: 'Todd Boward',
      email: 'mlklioqwe@xcv.as',
    },
    foundHelpful: 0,
    foundUnhelpful: 0,
    helpfulness: 0,
    moderation: {
      moderationStatus: 'APPROVED',
      manuallyChanged: false,
    },
  },
  {
    namespace: 'stores',
    entityId: '7bb38a7a-70b7-9cf3-fc80-584205694465',
    id: 'da194b50-2f44-4c7a-b34b-b57eb28973da',
    content: {
      media: [],
      rating: 5,
      title: 'First class',
      body: "Another great product from this site. Bought a bunch of other stuff, was never disappointed. Quality's great and delivery is always on time. Well on track to becoming my new favorite online shop!",
    },
    createdDate: new Date('2022-04-22T08:30:11.876Z'),
    updatedDate: new Date('2022-04-22T08:30:11.876Z'),
    author: {
      contactId: '500c29f8-af15-4f8f-a680-7e63a128f7ff',
      authorType: 'VISITOR',
      authorName: 'Caitlyn Tanner',
      email: 'gogogo@gogo.go',
    },
    foundHelpful: 0,
    foundUnhelpful: 0,
    helpfulness: 0,
    moderation: {
      moderationStatus: 'APPROVED',
      manuallyChanged: false,
    },
  },
  {
    namespace: 'stores',
    entityId: '7bb38a7a-70b7-9cf3-fc80-584205694465',
    id: 'fb65961c-0e83-4c5e-a45a-7f8ceec7b0ef',
    content: {
      body: `A friend recommended this item for me a few weeks ago. Was a bit skeptical at first but all the great reviews convinced me to try it. Gotta say, I'm glad I listened! The product was packaged well, arrived on time and the quality was great for this price range. 
      
      Will buy again.`,
      media: [],
      rating: 5,
      title: 'Pleasantly surprised!',
    },
    createdDate: new Date('2022-04-22T08:30:11.876Z'),
    updatedDate: new Date('2022-04-22T08:30:11.876Z'),
    author: {
      contactId: '4ed6a1b5-4004-4c93-ba04-067fbd9180d4',
      authorType: 'VISITOR',
      authorName: 'Bryce Callighan',
      email: 'kamonkamon@asd.com',
    },
    foundHelpful: 0,
    foundUnhelpful: 0,
    helpfulness: 0,
    moderation: {
      moderationStatus: 'APPROVED',
      manuallyChanged: false,
    },
  },
  {
    namespace: 'stores',
    entityId: '7bb38a7a-70b7-9cf3-fc80-584205694465',
    id: '4c353be0-7a79-4a80-99fa-293b338dfe6b',
    content: {
      media: [],
      rating: 4,
      title: 'Loved it.',
      body: 'My granddaughter had this for her 11th birthday. She loved it. Though it was smaller than we expected, but all in all it was ok.',
    },
    createdDate: new Date('2022-04-22T08:30:11.876Z'),
    updatedDate: new Date('2022-04-22T08:30:11.876Z'),
    author: {
      contactId: '5439a661-e09f-4064-b509-6244319f6ac2',
      authorType: 'VISITOR',
      authorName: 'Jenny Price',
      email: 'zsalsloskkwik@asd.asd',
    },
    foundHelpful: 0,
    foundUnhelpful: 0,
    helpfulness: 0,
    moderation: {
      moderationStatus: 'APPROVED',
      manuallyChanged: false,
    },
  },
  {
    namespace: 'stores',
    entityId: '7bb38a7a-70b7-9cf3-fc80-584205694465',
    id: 'a219e7a9-e8b9-4385-aa15-f5bcfc0918dd',
    content: {
      media: [],
      rating: 5,
      title: 'Lovely decoration',
      body: 'I use this as decoration in my living room and everyone loves it. Adds a little bit of fun as decoration and still looks smart. Had a bit of an issue with shipping, but Laura sorted it out no problem. 10/10 will order something again!',
    },
    createdDate: new Date('2022-04-22T08:30:11.876Z'),
    updatedDate: new Date('2022-04-22T08:30:11.876Z'),
    author: {
      contactId: '66748200-23ad-4a1e-bd27-7d4b257e31f2',
      authorType: 'VISITOR',
      authorName: 'Suny Singhud',
      email: 'fgdbvc@asd.sd',
    },
    foundHelpful: 0,
    foundUnhelpful: 0,
    helpfulness: 0,
    moderation: {
      moderationStatus: 'APPROVED',
      manuallyChanged: false,
    },
  },
  {
    namespace: 'stores',
    entityId: '7bb38a7a-70b7-9cf3-fc80-584205694465',
    id: '618f2ae2-8932-41af-9d33-f01d027f78a6',
    content: {
      media: [],
      rating: 5,
      title: 'Amazing product',
      body: "This product is amazing. I bought it twice already and honestly it's a game changer, especially for its value. I used to get something similar from larger retail stores but it was just overpriced and the quality was average. No thanks! I'll get something way cheaper and higher quality from sites like these, thank you very much. Highly recommend this to everyone!",
    },
    createdDate: new Date('2022-04-22T08:30:11.876Z'),
    updatedDate: new Date('2022-04-22T08:30:11.876Z'),
    author: {
      contactId: '5ce53e75-7d60-4263-8afe-9e575a683cc2',
      authorType: 'VISITOR',
      authorName: 'Dan Terez',
      email: 'jajajaj@aa.aa',
    },
    foundHelpful: 0,
    foundUnhelpful: 0,
    helpfulness: 0,
    moderation: {
      moderationStatus: 'APPROVED',
      manuallyChanged: false,
    },
    verified: true,
  },
  {
    namespace: 'stores',
    entityId: '7bb38a7a-70b7-9cf3-fc80-584205694465',
    id: '1419be2d-a543-4e76-82e3-4963eafce23c',
    content: {
      media: [],
      rating: 5,
      title: 'Support your local business!',
      body: 'This product is awesome - good quality and the materials are local. Wish there were more sites like this one that sold handcrafted products. Will definitely buy it again!',
    },
    createdDate: new Date('2022-04-22T08:30:11.876Z'),
    updatedDate: new Date('2022-04-22T08:30:11.876Z'),
    author: {
      contactId: '69f5178d-0d71-4661-9c8d-0027126f65b5',
      authorType: 'VISITOR',
      authorName: 'Sally Trumer',
      email: 'lalalala@lala.aa',
    },
    foundHelpful: 0,
    foundUnhelpful: 0,
    helpfulness: 0,
    moderation: {
      moderationStatus: 'APPROVED',
      manuallyChanged: false,
    },
    verified: true,
  },
  {
    namespace: 'stores',
    entityId: '7bb38a7a-70b7-9cf3-fc80-584205694465',
    id: '263bfca9-3edd-4b9c-ac30-93840fdbec20',
    content: {
      body: 'Everything is ok with the product, it just took too long to deliver. It said the delivery would take up to five days but it was delayed and arrived a week late. I needed it sooner than that because it was a gift. Oh well.',
      media: [],
      rating: 3,
      title: 'Slow delivery',
    },
    createdDate: new Date('2022-04-02T08:40:11.876Z'),
    updatedDate: new Date('2022-04-02T08:40:11.876Z'),
    author: {
      contactId: 'cdb62402-9ca2-4bcc-8766-e32c181e36f9',
      authorType: 'VISITOR',
      authorName: 'Barry Tate',
      email: 'otata@ata.ta',
    },
    foundHelpful: 0,
    foundUnhelpful: 0,
    helpfulness: 0,
    moderation: {
      moderationStatus: 'APPROVED',
      manuallyChanged: false,
    },
  },
  {
    namespace: 'stores',
    entityId: '7bb38a7a-70b7-9cf3-fc80-584205694465',
    id: 'dd8fe04c-1932-4ef6-bc0d-14dedcfcf003',
    content: {
      media: [],
      rating: 2,
      title: 'Too pricey',
      body: 'Was not impressed with the quality at this price range. The delivery came on time, but the packaging was cheap and everything inside was shaken. Definitely not worth the price tag!',
    },
    createdDate: new Date('2022-04-22T08:35:11.876Z'),
    updatedDate: new Date('2022-04-22T08:35:11.876Z'),
    author: {
      contactId: '121f47d6-8060-4054-8ffb-6be8d5ddb9eb',
      authorType: 'VISITOR',
      authorName: 'Ingrid Brown',
      email: 'kpklk@klsad.as',
    },
    foundHelpful: 0,
    foundUnhelpful: 0,
    helpfulness: 0,
    moderation: {
      moderationStatus: 'APPROVED',
      manuallyChanged: false,
    },
  },
  {
    namespace: 'stores',
    entityId: '7bb38a7a-70b7-9cf3-fc80-584205694465',
    id: '71d23d83-7f69-4d9c-b8f8-ce0d67bd1c90',
    content: {
      media: [],
      rating: 2,
      title: 'Wrong model',
      body: "Bought this as a gift for my son's birthday. Even though the description said it was the right model, it wasn't. My son was upset because he already had one of these from before, so we had to return it.",
    },
    createdDate: new Date('2022-04-20T08:30:11.876Z'),
    updatedDate: new Date('2022-04-20T08:30:11.876Z'),
    author: {
      contactId: '048cc7eb-c5d8-42f4-aef2-f1271eb9d965',
      authorType: 'VISITOR',
      authorName: 'Trudy Green',
      email: 'lslsls@lsd.as',
    },
    foundHelpful: 0,
    foundUnhelpful: 0,
    helpfulness: 0,
    moderation: {
      moderationStatus: 'APPROVED',
      manuallyChanged: false,
    },
  },
  {
    namespace: 'stores',
    entityId: '7bb38a7a-70b7-9cf3-fc80-584205694465',
    id: '0602ae98-dd43-4496-83e5-d6902f6cbcc3',
    content: {
      title: 'Looked better in the pictures',
      body: "Everything's alright with it, just looked better in the pictures. My house is a bit dim and doesn't get a lot of sunlight, so the product loses a bit of its flair. Make sure you have a well-lit home if you're getting this.",
      media: [],
      rating: 3,
    },
    createdDate: new Date('2022-04-21T08:30:11.876Z'),
    updatedDate: new Date('2022-04-21T08:30:11.876Z'),
    author: {
      contactId: '48e045e4-7c13-4e07-8fe2-66f136a27f1a',
      authorType: 'VISITOR',
      authorName: 'James Garden',
      email: 'gogo@gogo.go',
    },
    foundHelpful: 0,
    foundUnhelpful: 0,
    helpfulness: 0,
    moderation: {
      moderationStatus: 'APPROVED',
      manuallyChanged: false,
    },
  },
  {
    namespace: 'stores',
    entityId: '7bb38a7a-70b7-9cf3-fc80-584205694465',
    id: '8942ac7a-969f-47e2-8800-8425dea4adfb',
    content: {
      title: 'Had better',
      body: "Bought similar products from other stores before, this one's alright. For its price and quality though, I'd still go with the previous store I got it from. They market this as “locally sourced” but I'm not sure if I believe it.",
      media: [],
      rating: 3,
    },
    createdDate: new Date('2022-04-11T08:30:11.876Z'),
    updatedDate: new Date('2022-04-11T08:30:11.876Z'),
    author: {
      contactId: 'cbb47afd-752a-44b2-9322-e5a01d6ba810',
      authorType: 'VISITOR',
      authorName: 'Thum Ericsson',
      email: 'xcxcxc@cx.cx',
    },
    foundHelpful: 0,
    foundUnhelpful: 0,
    helpfulness: 0,
    moderation: {
      moderationStatus: 'APPROVED',
      manuallyChanged: false,
    },
  },
  {
    namespace: 'stores',
    entityId: '7bb38a7a-70b7-9cf3-fc80-584205694465',
    id: '33d5ce80-f16a-4f71-a47b-10932daace59',
    content: {
      media: [],
      rating: 3,
      title: 'Poor packaging',
      body: "While I understand business costs are high, I don't think it's a good reason to package something poorly. The box was torn when the product came. I don't know if it's the delivery service or poor packaging materials, but I definitely wasn't impressed.",
    },
    createdDate: new Date('2022-04-19T08:30:11.876Z'),
    updatedDate: new Date('2022-04-19T08:30:11.876Z'),
    author: {
      contactId: '14638b5c-bb67-45f4-83c6-2cbfd7ee56bf',
      authorType: 'VISITOR',
      authorName: 'Gregor Schulz',
      email: 'xkssk@asd.as',
    },
    foundHelpful: 0,
    foundUnhelpful: 0,
    helpfulness: 0,
    moderation: {
      moderationStatus: 'APPROVED',
      manuallyChanged: false,
    },
  },
] as Review[];
