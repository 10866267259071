import { mockReviews } from '~reviews/controller/lib/reviews-api-editor-mock';

// Define what % of entities should have no reviews
const NO_REVIEWS_PERCENTAGE = 30;
const uuidToRandomNumber = (uuid: string) => parseInt(uuid.split('-')[0], 16) || 1337;

// Copied from lodash source code
function shuffle(array: any[], seed: number) {
  const length = array == null ? 0 : array.length;
  if (!length) {
    return [];
  }
  let index = -1;
  const lastIndex = length - 1;
  const result = [...array];
  while (++index < length) {
    const rand = index + Math.floor(parseFloat(`0.${seed}`) * (lastIndex - index + 1));
    const value = result[rand];
    result[rand] = result[index];
    result[index] = value;
  }
  return result;
}

export const buildDemoContentForEntity = (uuid: string) => {
  const seed = uuidToRandomNumber(uuid);
  if (seed % 10 < NO_REVIEWS_PERCENTAGE / 10) {
    return {
      entityId: uuid,
      average: 0,
      total: 0,
      reviewList: [],
    };
  }

  const reviewList = shuffle(mockReviews, seed).slice(0, Math.max(1, seed % mockReviews.length));
  return {
    entityId: uuid,
    average: reviewList.reduce((acc, r) => acc + r.content!.rating!, 0) / reviewList.length,
    total: reviewList.length,
    reviewList,
  };
};
